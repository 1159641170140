/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* $primary-color: $my-brand-color;
// How you import this will depend heavily on your build setup 
// If using webpack though, `~` refers to node_modules 
// @import '~loaders.css/src/animations/line-scale.scss'  */
/* @import 'loaders.css/src/animations/line-scale.scss';
 
.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
} */

.font-body {
  font-family: Poppins;
}

body {
  font-family: Poppins;
}

:root {
  --theme-color: #5e2ced;
  --icon-theme-color: #303e4b;
  --icon-theme-color-light: #878a9c;
  --primary-text-color: #18203a;
  --secondary-text-color: #303e4b;
  --ternary-text-color: #969fa7;
  --color-white: #ffffff;
  --color-red: #b91c1c;
  --bordercolor-theme: 1px solid var(--theme-color);
  --bordercolor-red: 1px solid #ea5858;
  --customwhite-bordercolor: solid 1px #e6edf3;
  --customwhite-bgcolor: solid 1px #e6edf2;
  --custom-bordercolor: #eef2f7;
  --inputtextfield-color: #93a0ac;
  --color-green: #54d695;
  --color-yellow: #ff9d2b;
  --disabled-button-color: #eaecf0;
  --text-color: #18203a;
  --background-fullheight-color: #f7f8fa;
}

.user-img-rounded {
  @apply h-10 w-10 rounded-full;
}

.cust-pr-20 {
  padding-right: 30px !important;
}

.responsivePadding {
  @apply xs:px-2 sm:px-10 2xl:px-48;
}

/* .main-tag-class {
  @apply flex-1 relative overflow-y-auto focus:outline-none scrollbar-hide;
} */

/***** Input Box *****/
.cust-input-field {
  @apply appearance-none block w-full px-2 py-2 rounded-md shadow-sm border border-gray-200 placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight text-xs;
}

/***** Input Box *****/
.cust-transparent-input-field {
  @apply appearance-none block w-full px-0.5 py-2 placeholder-ternary sm:text-sm outline-none border-none focus:outline-none focus:ring-0 focus:ring-offset-0;
}

/***** Input Box wull width *****/
.cust-input-field2 {
  @apply mt-1 block w-full pl-3 pr-10 py-2 text-xs border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-xs rounded-md;
}

/***** Check Box *****/
.cust-check-box {
  @apply h-4 w-4 text-primary border-ternary rounded;
}

/***** Custom Paragraph *****/
.paragraph-seconday {
  @apply mt-1 max-w-2xl text-sm text-ternary;
}

/***** Custom Buttons *****/
.btn-primary-circle {
  @apply inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-custWhite bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ternary;
}

.btn-primary {
  @apply w-full flex justify-center  py-2 px-4 border border-transparent rounded-md shadow-sm text-xs font-light text-custWhite bg-primary hover:bg-primary hover:opacity-90;
}

.btn-primary-small {
  @apply w-14 flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-xs font-light text-custWhite bg-primary hover:bg-primary hover:opacity-90;
}

.btn-primary-white {
  @apply w-full flex justify-center py-2 px-4 border border-gray-400 rounded-md shadow-sm text-xs font-light bg-custWhite hover:bg-gray-50 hover:opacity-90;
}

.btn-secondary {
  @apply w-full flex justify-center  py-2 px-4 border border-transparent rounded-md shadow-sm text-xs font-light text-custWhite bg-menu hover:opacity-90;
}

.btn-secondary-2 {
  @apply w-full flex justify-center py-2 px-4 text-sm text-gray-600 hover:opacity-90;
}

.btn-white {
  @apply w-full flex justify-center  py-2 px-4 border border border-gray-200 rounded-md shadow-sm text-xs font-light text-primary bg-custWhite hover:opacity-90;
}

.btn-disabled {
  @apply w-full flex justify-center mb-3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite cursor-not-allowed bg-gray-300 hover:bg-gray-600;
}

.btn-danger {
  @apply w-full flex justify-center mb-3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-danger hover:bg-dangerHover;
}

.tbl-btn-primary {
  @apply inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-custWhite bg-primary hover:bg-primaryHover;
}

.btn-primary-outline {
  @apply inline-flex items-center px-2.5 py-1.5 border border-primary text-xs font-medium rounded text-primary bg-custWhite;
}

.tbl-btn-danger {
  @apply inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-custWhite bg-danger hover:bg-dangerHover;
}

.btn-primary-right {
  @apply inline-flex justify-center mb-3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover;
}

.btn-closeModal {
  @apply bg-custWhite rounded-md text-ternary hover:text-secondary;
}

/* Card */
.btn-card {
  @apply bg-quaternary rounded-full flex items-center text-secondary hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary;
}

/***** Saler Management Tabs *****/
.sales-table-td {
  @apply px-6 py-4 whitespace-nowrap text-left;
}

.sales-table-th {
  @apply px-6 py-3 text-left text-xs font-medium text-ternary uppercase tracking-wider;
}

.sales-table-td-text {
  @apply text-sm text-ternary;
}

.saler-date-tabs {
  @apply relative min-w-0 flex-1 overflow-hidden bg-custWhite py-4 px-2 text-sm font-medium text-center hover:bg-quaternary focus:z-10 cursor-pointer;
}

/***** Custom Badge *****/
.card-badge {
  @apply inline-flex items-center px-3 mx-1 py-0.5 rounded-full text-xs font-thin bg-quaternary text-secondary;
}

.card-badge-2 {
  @apply inline-flex items-center px-3 mx-1 py-0.5 rounded-full text-xs font-thin bg-quaternary text-primaryLight;
}

.card-badge-3 {
  @apply inline-flex items-center px-3 mx-1 py-0.5 rounded-full text-xs font-thin bg-primary text-custWhite;
}

.card-badge-danger {
  @apply inline-flex items-center px-3 mx-1 py-0.5 rounded-full text-xs font-thin bg-danger text-custWhite;
}

.card-badge-warning {
  @apply inline-flex items-center px-3 mx-1 py-0.5 rounded-full text-xs font-thin bg-warning text-custWhite;
}

.card-badge-success {
  @apply inline-flex items-center px-3 mx-1 py-0.5 rounded-full text-xs font-thin bg-success text-custWhite;
}

.badge-icon {
  @apply px-1 h-2.5;
}

.text-box-lable {
  @apply text-sm font-normal text-secondary;
}

.sidebar-icons {
  @apply mx-3 flex-shrink-0 h-6 w-6;
}

.messages-tab-button {
  @apply relative inline-flex items-center px-4 py-2  border  text-sm font-medium text-gray-700 hover:bg-gray-50;
}

.btn-social-login {
  @apply w-full flex justify-center  py-2 px-4 border  rounded-md shadow-sm text-xs bg-white hover:bg-gray-50;
}

/**** Modal ****/

.bigmodal {
  width: 90% !important;
}
/* Sticky Modal */
.sticky-top {
  height: 700px !important;
  overflow-y: auto !important;
  padding: 0px !important;
}

.sticky-header {
  position: sticky !important;
  top: 0 !important;
  background-color: #fff !important;
}

/* Slider Modal Header */
.slider-modal-headtext {
  @apply text-lg font-medium text-primary;
}

.end-item {
  @apply flex flex-wrap content-center justify-end;
}

.carousel {
  display: inline-flex;
  overflow-x: hidden;
  /*  scroll snap is a great feature which will center the image on snap on touch screen devices  */
  scroll-snap-type: x mandatory;
  /* all below will hide the scrollbar on all browsers.    */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

span.rtl {
  direction: rtl;
}

div.rtl {
  direction: rtl;
}

span.ltl {
  direction: ltl;
}

p.ltl {
  direction: ltl;
}

/**** Drop Zone Cover ****/
.dropzonecover {
  text-align: center;
  border: 1px dashed var(--theme-color);
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  cursor: pointer;
  border-radius: 6px;
  text-align: center;
  width: 100%;
  /* padding: 36px; */
  /* height: 100px; */
  position: relative;
  background: --color-white;
}

/**** Drop Zone ****/
.dropzonenew {
  text-align: center;
  border: 1px dashed var(--theme-color);
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  cursor: pointer;
  border-radius: 6px;
  text-align: center;
  width: 100%;
  /* padding: 36px; */
  height: 100px;
  position: relative;
  background: --color-white;
}

.img_wrpcover {
  /* display: inline-block; */
  position: relative;
}

.dropimgcover {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  /* object-fit: cover; */
}

.dropimg {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  object-fit: cover;
}

.img_wrp {
  display: inline-block;
  position: relative;
}

.img-wrp-close {
  position: absolute;
  top: 0px;
  right: 0px;
}

.cust-dash {
  border: 0.5px dashed var(--theme-color);
}

/**** Store location Search Input ****/
.location-search-input {
  @apply appearance-none block w-full px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm;
}

.autocomplete-dropdown-container {
  @apply z-10 mt-1 w-full bg-custWhite shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-custblack ring-opacity-5 overflow-auto focus:outline-none sm:text-sm;
}

.suggestion-item {
  @apply inset-y-0 right-0 flex items-center py-2 pl-3 pr-9 text-secondary;
}

.suggestion-item--active {
  @apply text-primary cursor-default select-none relative py-2 pl-3 pr-9;
}

/**** Map ****/
.map-details {
  @apply mt-1 text-xs font-semibold text-secondary;
}

.map-details span {
  @apply text-xs font-semibold text-ternary;
}

.info-wrapper {
  @apply py-6;
}

/**** Table related ****/
.table-hover-transition {
  @apply transition ease-in-out duration-500 hover:bg-ternaryHover;
}

.div-hover-transition {
  @apply transition ease-in-out duration-500 hover:bg-ternaryHoverDiv;
}

.icon-hover-transition {
  @apply transition duration-500 ease-in-out transform hover:-translate-y-0 hover:scale-110;
}

/**** Drop Zone Cover ****/
.dropzone-round {
  /* text-align: center;
    border: 1px dashed var(--theme-color);
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
    width: 100%;

    position: relative;
    background: --color-white; */
  @apply h-20 w-20 rounded-full md:w-40 md:h-40;
}

/**** Date time picker ****/
.react-datepicker-popper {
  position: static !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  transform: translate(0, 0) !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

/**** Variation Details ****/
.variation-video-play-icon {
  @apply absolute bottom-2 h-6;
}

.variation-video-play-icon-fee-page {
  @apply absolute bottom-2 left-20 h-6;
}

/**** Product Fee Carousel ****/
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet {
  color: #2b6cb0;
  /*Set to match the Tailwind colour you want the active one to be */
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

/* Notification Related */
.notificatino-icon-text {
  @apply flex-shrink-0 w-4 h-4 rounded-full bg-danger  top-0 left-4 text-custWhite text-xs;
}

.policyHeader {
  @apply bg-primary text-custWhite px-10 py-6;
}

.center-item {
  @apply flex flex-wrap content-center justify-center;
}
.start-item {
  @apply flex flex-wrap content-center justify-start;
}
.end-item {
  @apply flex flex-wrap content-center justify-end;
}

@media (min-width: 280px) and (max-width: 625px) {
  .react-datepicker__time-container {
    width: 100% !important;
  }
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 0px !important;
  }
}

/*** Support FAQ ***/
.pagination {
  @apply flex justify-end;
}

.pagination > li {
  clear: both;
  padding: 15px;
  border-radius: 30px;
  margin-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
}

.pagination > .active > a,
.pagination > .active > a:focus {
  color: var(--theme-color);
}

/* Revenue report */
.revenue-report-search-field {
  @apply block w-full h-full pl-8 pr-3 py-2 text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md;
}

/*****  Profile management *****/

/* Store information */
.bigdropbox {
  width: 395px;
  height: 220px;
  padding: 0px;
  text-align: center;
  border: 1px dashed var(--theme-color);
  border-radius: 6px;
  background: var(--color-white) !important;
}

.hide-feature {
  @apply hidden;
}

.react-tel-input .special-label {
  display: none !important;
}

.btn-white-delete {
  @apply w-full flex justify-center  py-2  border  rounded-md shadow-sm text-sm font-medium  bg-white hover:bg-white  hover:bg-gray-100;
}
.btn-white {
  @apply w-full flex justify-center  py-2 px-4 border  rounded-md shadow-sm text-sm font-medium  bg-white hover:bg-white;
}
.btn-light-primary {
  @apply w-full flex justify-center  py-1.5 px-2 border  rounded-md shadow-sm text-xs font-medium  bg-primaryHover hover:bg-primaryHover text-primary;
}

.btn-red-primary {
  @apply flex justify-center  py-2 px-4 border  rounded-md shadow-sm text-sm font-medium  bg-primaryHover hover:bg-primaryHover text-red-700;
}

.team-member-circularprogressbar.CircularProgressbar {
  width: 10% !important ;
}

@media (max-width: 1024px) {
  .team-member-circularprogressbar.CircularProgressbar {
    width: 22% !important ;
  }
}

.team-member-circularprogressbar.CircularProgressbar .CircularProgressbar-path {
  stroke: #5e2ced !important;
}

.focus\:border-transparent:focus {
  border-color: #d0d5dd !important;
}
.rdrDateDisplayWrapper {
  display: none !important;
}
.rdrDefinedRangesWrapper {
  display: none !important;
}

.bigmodal {
  max-width: 90% !important;
}

.email-item-mycontacts {
  position: relative;
  background-color: white;
  -webkit-transition: background-color 2s ease-in;
  transition: background-color 2s ease-in;
}

.overlay-item-email-mycontacts {
  background: white;
  /* position: absolute; */
  margin-left: 20px;
  display: none;
}

.phone-item-mycontacts:hover .overlay-item-phone-mycontacts {
  display: flex;
  /* background-color: black; */
}

.email-item-mycontacts {
  position: relative;
  background-color: white;
  -webkit-transition: background-color 2s ease-in;
  transition: background-color 2s ease-in;
}

.overlay-item-email-mycontacts {
  background: white;
  /* position: absolute; */
  margin-left: 20px;
  display: none;
}

.email-item-mycontacts:hover .overlay-item-email-mycontacts {
  display: flex;
  /* background-color: black; */
}
.planbox {
  background: #f9fafb;
  /* Gray/100 */

  border: 1px solid #f2f4f7;
  border-radius: 8px;
}

.react-datepicker__tab-loop {
  /* position: absolute !important;
    margin-left: 255px !important;
    margin-top: 35px !important;
    z-index: 1 !important; */
}

.customStripeLabelCss {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
  text-align: left !important;
}

.stripelabel {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.stripebutton {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow:
    0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  /* background-color: #4da1ff; */
  background-color: var(--theme-color);
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.stripeform {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
}

.stripebutton:hover {
  color: #fff;
  cursor: pointer;
  /* background-color: #7795f8;*/
  background-color: var(--theme-color);
  transform: translateY(-1px);
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}

.stripeinput,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  /* max-width: 500px; */
  padding: 10px 14px;
  font-size: 1.1em;
  font-family: 'Montserrat-Regular';
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: var(--color-white);
  visibility: visible;
  /* width: 600px; */
}

.stripeinput::placeholder {
  color: #aab7c4;
}

.stripeinput:focus,
.StripeElement--focus {
  box-shadow:
    rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.InputElement {
  font-size: 1.1em;
}

.keyblurred {
  height: 24px;
  width: 240px;
  background-image: url(/src/assets/images/secret.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 240px 24px;
  margin: -2px 0;
}

.PressableContext {
  z-index: 1;
  vertical-align: middle;
  word-break: normal;
  transition: color var(--sail-duration);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: auto;
  user-select: auto;
  background: 0 0;
  border: 0;
  outline: 0;
  flex: 0 auto;
  margin: 0;
  text-decoration: none;
  position: relative;
}

.react-confirm-alert-button-group {
  margin-top: 30px !important;
}

.react-confirm-alert-button-group > button {
  background-color: #5e2ced !important;
  color: white;
  font-size: 12px !important;
  font-family: Poppins !important;
}

.react-confirm-alert-body {
  font-size: 14px !important;
  font-family: Poppins !important;
  width: 450px !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.react-confirm-alert-body > h1 {
  font-size: 16px !important;
  font-weight: bold;
  font-family: Poppins !important;
}

.react-confirm-alert-body > p,
ul {
  font-size: 12px !important;
  font-weight: normal;
  font-family: Poppins !important;
}
.react-confirm-alert-body ul {
  list-style: disc;
  margin-left: 12px;
}
.react-confirm-alert-body ul li {
  margin: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  font-size: 13px;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip-right {
  top: -7px;
  left: 291%;
}

.json-container {
  font-family: Poppins;
  /* background-color: #f9f9f9; */
  /* padding-top: 20px; */
  border-radius: 5px;
  width: fit-content;
  /* margin: 20px auto; */
}

.json-key {
  color: #c11574; /* Red color for keys */
  font-size: 11px;
  font-weight: normal;
}

.json-value {
  color: #027a48; /* Blue color for string values */
  font-size: 11px;
  font-weight: normal;
}

.json-null {
  color: #9e9e9e; /* Grey color for null values */
  font-style: italic;
  font-size: 11px;
  font-weight: normal;
}

.json-array {
  color: #344054; /* Green color for arrays */
}

.json-level {
  margin-left: 20px;
}

.main-login {
  background-image: url('./assets/images/bgpattern.png') !important;
  background-position: right !important; /* Center the image */
  background-repeat: no-repeat !important; /* Do not repeat the image */
  background-size: contain !important; /* Resize the background image to cover the entire container */
}

.react-confirm-alert-overlay {
  background-color: #66708575 !important;
}

/* Chrome, Safari, Edge, and Opera */
.no-clear::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

/* Firefox */
.no-clear::-moz-search-cancel-button {
  display: none;
}

/* IE */
.no-clear::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.updateAvailableIcon {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

/* =================================================== Start Toggle Button ========================================== */
.switch-button-container {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 31px;
}
.switch-button-container > input {
  visibility: hidden;
}
.switch-button-container-user {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 31px;
}
input:checked + .switch-slider {
  /* background-color: #4da1ff; */
  background-color: var(--theme-color);
}
.switch-slider.switch-round {
  border-radius: 43px;
}
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 5px;
  left: -2px;
  right: 9px;
  bottom: 6px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-slider.switch-round:before {
  border-radius: 50%;
}
input:checked + .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch-slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: var(--color-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
/* =================================================== End Toggle Button ========================================== */

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.error-message::before {
  content: '⚠️ '; /* Adds a warning icon before the error message */
}

.react-datepicker-popper {
  z-index: 1050; /* Adjust based on your app's z-index stack */
}
